.home section {
  padding-top: 78px;
  padding-bottom: 78px; }

#budowa {
  height: 800px; }
  #budowa p {
    margin-top: 340px;
    text-align: center; }

#home {
  height: 500px;
  padding-top: 132px;
  position: relative;
  overflow: hidden; }
  #home img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px; }
  #home .content {
    position: relative;
    z-index: 2; }
    #home .content h1 {
      color: white; }
      @media screen and (max-width: 1199px) {
        #home .content h1 {
          text-align: center; } }
    #home .content p {
      background: #008CCC;
      color: white;
      display: inline; }
      @media screen and (max-width: 1199px) {
        #home .content p {
          display: block;
          text-align: center; } }

#oferta .item {
  padding: 20px;
  background: #231f20; }
  #oferta .item h2 {
    color: white; }
  #oferta .item p {
    color: white; }

#uslugi {
  background: #008CCC; }
  #uslugi h2 {
    color: white; }
  #uslugi p {
    color: white;
    font-size: 15px;
    font-weight: 300; }
  #uslugi .after-block {
    width: 100%; }
  #uslugi .item {
    background: #ade4fb;
    padding: 30px 15px; }
    #uslugi .item .btn {
      width: 100%;
      border-radius: 0px;
      font-weight: 500;
      margin-top: 20px;
      background: #008CCC; }
    #uslugi .item h2 {
      margin-top: 20px;
      color: #231f20; }
    #uslugi .item p {
      color: #231f20;
      font-size: 15px;
      font-weight: 300; }

#background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 888px;
  position: relative;
  padding: 0px; }
  #background .wrapper-frame-block {
    position: relative;
    width: 100%;
    height: 1000px;
    overflow: hidden; }
    #background .wrapper-frame-block:before {
      top: 0px;
      bottom: 0px;
      width: 100px;
      left: 0px;
      height: 1000px; }
  #background .frame-block {
    position: absolute;
    top: 0px;
    left: 50%;
    right: 0px;
    bottom: 0px;
    height: 100%;
    z-index: 10;
    transform: translateX(-50%); }
  #background .right {
    position: absolute;
    top: 8%;
    right: 0px;
    background: white;
    width: 50%;
    height: 42%;
    padding: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8; }
    #background .right h2 {
      color: #008CCC; }
  #background .left {
    position: absolute;
    bottom: 8%;
    left: 0px;
    background: white;
    width: 50%;
    height: 42%;
    padding: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9; }
    #background .left h2 {
      color: #008CCC; }

#background2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 250px;
  position: relative; }

#galeria {
  padding-top: 190px; }
  #galeria .img-fluid {
    border: 4px solid #008CCC; }

#partnerzy {
  background: #f5f5f5; }
  #partnerzy img {
    height: 50px; }

#kontakt {
  padding-top: 0px;
  padding-bottom: 0px; }
  #kontakt h2 {
    padding-top: 78px; }
  #kontakt p {
    color: gray; }
  @media screen and (max-width: 1199px) {
    #kontakt h4 {
      margin-bottom: 78px; } }
